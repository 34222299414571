export default [
	{
    id: 0,
		destination: "Nedefinita"
  },
  {
    id: 1,
		destination: "Campanie marketing"
  },
  {
    id: 2,
		destination: "Studiul pietei"
  },
  {
     id: 3,
		destination: "Promovarea pe piete"
  },
  {
    id: 4,
		destination: "Protocol"
  },
  {
    id: 5,
		destination: "Reclama si publicitate"
  },
  {
    id: 6,
		destination: "Cheltuieli sociale"
	},
];
